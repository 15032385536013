@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  
  html {
      scroll-behavior: smooth;
  }

  .screen-margin {
    margin-left: 6.5%;
    margin-right: 6.5%;
  }

  .screen-margin-results {
    margin-left: 4%;
    margin-right: 4%;
    margin-top: 3%;
    display: flex;
    /* height: 100vh; Adjust as needed */

  }

  .screen-margin-results2 {
    margin-left: 6.5%;
    margin-right: 6.5%;
    margin-top: 0%;
  }

  .filter-margin-results {
    margin-left: 6.5%;
    margin-right: 6.5%;
    margin-top: 5%;
    padding-bottom: 30vh;
  }

  .screen-margin-details {
    margin-left: 6.5%;
    margin-right: 6.5%;
    margin-top: 5%;
  }

  .subcomponent-border {
    border: 0.5px solid #054075;
    /* margin-left: 3%;
    margin-right: 3%; */
  }

  .contact-us {
    color: white;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    padding-top: 0.5rem; /* 12px */
    padding-bottom: 0.5rem; /* 12px */
    border: solid 1px;
    margin-right: 1em;
    border-radius: 0.5rem;
    font-size: 0.75em;
  }

  .component-title {
    color: #64748b;
    font-size: 1.875rem; /* 30px */
    line-height: 2.25rem; /* 36px */
    font-weight: 600;
    margin-top: 60px;
  }

  .component-subtitle {
    color: #054075;
    font-size: 1.5rem; /* 30px */
    line-height: 1.75rem; /* 36px */
    font-weight: 500;
    margin-top: 40px;
  }

  .component-border {
    border: 1px solid #64748b;
    width: 100%;
  }

  .input-text {
    color: #054075;
    font-weight: 600;
  }

  .whos-applying {
    width: 325px;
  }

  .applicants-layout {
    display: flex;
    flex-direction: row;
    justify-content: start;
  }

  .zipcode-layout {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }

  .zipcode-input {
    width: 325px;
    border: 1px solid #054075;
    border-radius: 0.5rem;
  }

  .zipcode-county {
    width: 325px;
    border: 1px solid black;
    border-radius: 0.25rem;
    padding: 1rem;
  }

  .zipcode-county:hover {
    background: #054075;
    color: white; 
  }

  .applicants-layout {
    display: flex;
    flex-direction: column;
  }

  .age-input {
    border: 1px solid #054075;
    border-radius: 0.5rem;
    width: 90px;
    padding-top: 0.25rem; /* 8px */
    padding-bottom: 0.25rem; /* 8px */
  }

  .applicants-inputs-text {
    font-size: 0.85rem;
    line-height: 0.6rem;
    color: black;
    font-weight: 600;
  }

  .applicants-age-sex {
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
    justify-content: start;
  }

  .applicants-age-sex-input-box {
    margin-top: 8px;
  }


  .sex-toggle {
    display: flex;
    flex-direction: row;
    border-radius: 0.5rem;
    width: 40px;
  }

  .sex-toggle-selected {
    color: white;
    background: #054075;
    border: 1px solid #054075;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem; 
  }

  .sex-toggle-unselected {
    color: black;
    background: white;
    border: 1px solid #054075;
    padding-top: 0.25rem; /* 8px */
    padding-bottom: 0.25rem; /* 8px */
  }

  .option-text {
    font-size: 0.8rem;
  }

  .applicant-box-buttons-layout {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .applicant-box-buttons {
    background: white;
    color: black;
    font-size: 1rem;
    border: 1px solid black;
    border-radius: 0.5rem;
    padding: 10px;
    width: 120px;
  }

  .applicant-box-buttons:hover {
    background: #054075;
    color: white;
  }

  .disabled-button {
    background: #868686;
    color: #E2E2E2;
  }

  .disabled-button:hover {
    background: #868686;
    color: #E2E2E2;
  }

  .add-applicant-button {
    border: 1px solid #054075;
    border-radius: 0.5rem;
    text-align: center;
    color: #054075;
    width: 325px;
    font-size: 0.90rem;
  }

  .add-applicant-button:hover {
    background: #054075;
    color: white;
    cursor: pointer;
  }

  .edit-icon {
    width: 20px;
    height: 20px;
  }

  .household-input {
    width: 225px;
    border: 1px solid #054075;
    border-top-left-radius: 0.5rem; /* 8px */
    border-bottom-left-radius: 0.5rem; /* 8px */ 
    border-top-right-radius: 0; /* 8px */
    border-bottom-right-radius: 0; /* 8px */ 
  }

  .household-income-input {
    width: 325px;
    border: 1px solid #054075;
    border-radius: 0.5rem;
  }

  .decrement-button {
    border: 1px solid #054075;
    border-left: 0;
    background: #E2E2E2;
    padding-left: 1.25rem; /* 20px */
    padding-right: 1.25rem; /* 20px */
    font-size: 1.25rem; /* 20px */
    line-height: 1.75rem; /* 28px */
  }

  .increment-button {
    border: 1px solid #054075;
    border-left: 0;
    background: #E2E2E2;
    padding-left: 1.25rem; /* 20px */
    padding-right: 1.25rem; /* 20px */
    font-size: 1.25rem; /* 20px */
    line-height: 1.75rem; /* 28px */
    border-top-right-radius: 0.5rem; /* 8px */
    border-bottom-right-radius: 0.5rem; /* 8px */ 
  }

  .subsidy-output {
    border: 1px solid #0D930A;
    background: rgba(64, 233, 182, 0.11);
    width: 325px;
    border-radius: 0.5rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .subsidy-output-no-subsidy {
    border: 1px solid #EDA00C;
    background: #F8E589;
    width: 325px;
    border-radius: 0.5rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .subsidy-incomplete {
    border: 1px dashed #1F7401;
    border-radius: 0.5rem;
    width: 325px;
    color: #868686;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    font-style: italic;
  }

  .subsidy-amount-text {
    font-size: 2rem;
    color: #1F7401;
    font-weight: 600;

  }

  .provider-input {
    width: 600px;
    /* border: 1px solid #054075; */
    border-radius: 0.5rem;
    font-size: 1rem;
  }

  .drugs-provider-result-add-button {
    padding-left: 0.5rem; /* 20px */
    padding-right: 0.5rem; /* 20px */
    padding-top: 0.5rem; /* 12px */
    padding-bottom: 0.5rem; /* 12px */
    margin-top: 10px;
    max-width: 60px;
  }

  .drugs-provider-result-spacing {
    padding-left: 0.5rem; /* 20px */
    padding-right: 0.5rem; /* 20px */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .navigate-button {
    background: #054075;
    color: white;
    text-align: center;
    padding: 1rem 1.5rem;
    border-radius: 0.5rem;
    font-size: 1.2rem;
  }

  .secondary-button {
    color: black;
    border: 1px solid black;
    text-align: center;
    padding: .5rem;
    border-radius: 0.5rem;
  }

  .issuer-name-logo {
    max-width: 400px;
  }

  .plan-card {
    background: rgba(245, 245, 245, 0.72);
    border: 1px solid black;
    /* box-shadow: 1px 0px 1px gray; */
    border-radius: 0.5rem;
    margin-top: 15px;
  }

  /* .plan-card:hover {
    background: #F2FCFF;
    border: 1px solid black;
    box-shadow: 1px 1px 1px gray;
    border-radius: 0.5rem;
  } */

  .plan-card-logo {
    max-width: 80px;
  }
  
  .hyperlink-text {
    color: #1674CB;
  }

  .summary-table {
    width: 70%;
  }

  .summary-table-allrow {
    background: #F2FCFF;
    font-weight: 400;
    width: 70%;
  }

  .table-header-col-element {
    margin-left: 20%;
  }

  .table-col-element {
    margin-left: 17%;
  }

  .summary-compare-button {
    padding: 0.75rem;
  }

  .summary-compare-button-selected {
    background: #054075;
    color: white;
    padding: 0.75rem;
    border-radius: 0.85rem;
  }
  
  .filter-button {
    border: 1px solid #054075;
    color: #054075;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    font-weight: 600;
  }

  .filter-button:hover {
    border: 1px solid #054075;
    background: #F2FCFF;
    color: #054075;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    font-weight: 600;
  }

  .filter-button-active {
    background: #F2FCFF;
  }

  .details-button {
    width: 60%;
    padding: 0.5rem;
    border-radius: 0.5rem;
    font-weight: 500;
    border: 1px solid #054075;
    color: #054075;
  }

  .details-button:hover {
    width: 60%;
    padding: 0.5rem;
    border-radius: 0.5rem;
    font-weight: 500;
    border: 1px solid #054075;
    color: white;
    background: #054075;
  }

  .plan-pill {
    background: white;
    border: 1px solid #054075;
    padding: 1rem;
    min-width: 200px;
    max-width: 18%;
    /* border-radius: 1rem; */
  }

  .pill-logo {
    height: 10px;
  }

  /* usage input classes */
  .usage-input-col-header{
    font-size: 0.85rem;
    line-height: 0.6rem;
    color: black;
    font-weight: 600;
  }

  .claim-input-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 50px;
    text-align: center;
  }

  .get-claim-grid {
    display: grid;
    grid-template-columns: 3fr 2fr 1fr;
    text-align: left;
    max-width: 500px;
    padding: 0 20px;

  }

  .usage-input-val {
    margin: 0 8px;
    border: 1px solid gray;
    border-radius: 4px;
    text-align: center;
  }

  .add-claim-icon {
    width: 20px;
    height: 20px;
    display: inline;
  }

  .compare-footer-bar {
    margin-left: 6.5%;
    margin-right: 6.5%;
  }

  .footer {
    background-color: #EDFAFF;
    border-top: 1px solid #054075;
  }

  .compare-selected-btn {
    color: white;
    background-color: #054075;
    font-size: 14px;
    min-width: 180px;
  }

  .explain-row {
    display: flex;
    width: 95%; /* Ensure the container takes up full width */
    justify-content: space-between; /* Space out children */
    border: 1px solid black; /* Border around the entire row for clarity */
    /* margin-bottom: 5px; Spacing between rows */
  }
  
  .explain-categories,
  .explain-subcategories {
    flex: 1 1 16%; /* Flex grow, flex shrink, flex basis */
    padding: 5px;
    border-right: 1px solid black; /* Border only on the right */
  }
  
  .explain-descriptions {
    flex: 1 1 16%; /* Flex grow, flex shrink, flex basis */
    padding: 5px;
  }

  .explain-subcategories {
    padding-left: 50px;
  }
  
  .explain-combined {
    width: 95%;
    /* margin-bottom: 10px; Spacing below the header */
    border: 1px solid black;
    background-color: #054075;
    color: white;
    text-align: center;
  }

  .coverage-title {
    width: 12%;
  }

  .coverage-row {
    display: flex;
    flex-direction: column;
    min-width: 450px;
    width: 40%; /* Ensure the container takes up full width */
    justify-content: space-between; /* Space out children */
    /* border: 1px solid black; Border around the entire row for clarity */
    /* margin-bottom: 5px; Spacing between rows */
  }

  .coverage-input {
    width: 70%;
    border-right: 1px solid black;
    color: #054075;
  }

  .coverage-element {
    padding: 5px 10px;
    border: 1px solid black;
    color: #AC0202;
  }

  h2 {
    font-size: 18px;
  }

}

  .modal-bkgd {
    display: flex;
    position:fixed;
    margin: auto;
    z-index: 0;
    top: 0;
    bottom:0;
    right: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    border-radius: 5px;
    background-color: rgba(102,102,102,.2);
  }

  .modal {
    margin: auto auto;
    color: black;
    width: 60vw;
    max-width: 800px;
    height: 60vh;
    box-shadow: 0px 0px 6px 2px #666;
    background-color: white;
    border-radius: 5px;

    padding: 20px;
  }

  .scenario-btn {
    width: 325px; 
  }

  
  .left-panel {
    flex: 0 0 20%; 
    position: -webkit-sticky; 
    position: sticky;
    top: 0;
    height: 100vh;
    overflow-y: auto; 
    /* border-right: 1px solid #ddd;  */
    background: white; 
}

.left-panel2 {
    flex: 0 0 0%; 
}

.right-panel {
    flex: 1; 
    padding-left: 16px; 
}


.no-compare-added-text {
  width: 90vw;
}

.no-match-text {
  width: 72vw;
}
